import React, { useContext, useEffect } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { mainFunctions } from "../providers/MainProvider";
import ConsentBanner from "../component/ConsentBanner";

export default function Izimerchant() {
  const { merchantRef } = useContext(mainFunctions);

  useEffect(() => {
    async function gotoTop() {
      await merchantRef.current.scrollIntoView();
    }

    gotoTop();
  }, []);
  return (
    <>
      <ConsentBanner />
      <Header />
      <section className="hero2 merchant_bg" ref={merchantRef}>
        <div className="hero_title">IZI MERCHANT</div>
        <div className="hero_text">
          Elevate your business with this technology. With our array of Embedded
          Banking solutions, you can digitalize your small or medium-sized
          business, Manage your inventory, perform cashier services and manage
          customers' wallet all from IZI MERCHANT
        </div>
      </section>
      <section className="section_light">
        <div className="section_list2 row mb-5">
          <div className="col-xs-12 col-sm-12 col-md-4 list_box">
            <div className="list_item">
              <div className="image cashier"></div>
              <div className="section_list_title">CASHIER</div>

              <div className="section_list_sub">
                Get paid seamlessly at point of sale, the IZI MERCHANT's cashier
                is a simple and cost-effective way to accept payments from
                customers.
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-4 list_box">
            <div className="list_item">
              <div className="image customer_wallet"></div>
              <div className="section_list_title">CUSTOMER WALLET</div>
              <div className="section_list_sub">
                With this white-labelled custom wallet app, your customers can
                make purchases of all of your goods and services. You can also
                set up rewards, offer sale vouchers, and other promotional
                benefits.
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-4 list_box">
            <div className="list_item">
              <div className="image inventory"></div>
              <div className="section_list_title">INVENTORY MANAGEMENT</div>
              <div className="section_list_sub">
                Seamlessly integrate your products, service, resources and staff
                digitally into a single platform.
              </div>
            </div>
          </div>
        </div>
        <center>
          <a href="https://panel.izifin.com/auth/getstarted">
            <div className="btn btn_solid get_started_btn">Get started</div>
          </a>
        </center>
      </section>
      <Footer />
    </>
  );
}
