import React, { createRef } from "react";

export default function MainProvider(props) {
  const productsRef = createRef();
  const aboutRef = createRef();
  const pricingRef = createRef();
  const privacyRef = createRef();
  const kycRef = createRef();
  const bnplRef = createRef();
  const merchantRef = createRef();
  const innerRef = createRef();
  const validText = (value) => {
    if (value === null || typeof value === "undefined") {
      return false;
    } else if (value.length > 3) {
      return true;
    } else {
      return false;
    }
  };

  const validPhone = (value) => {
    if (value === null || typeof value === "undefined") {
      return false;
    } else if (value.length > 12) {
      return true;
    } else {
      return false;
    }
  };

  const validEmail = (value) => {
    if (value === null || typeof value === "undefined") {
      return false;
    } else if (
      /^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(value)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <mainFunctions.Provider
      value={{
        validEmail,
        validPhone,
        validText,
        productsRef,
        aboutRef,
        pricingRef,
        privacyRef,
        innerRef,
        kycRef,
        bnplRef,
        merchantRef,
      }}
    >
      {props.children}
    </mainFunctions.Provider>
  );
}

export const mainFunctions = React.createContext();
