import React, { useContext, useEffect } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { mainFunctions } from "../providers/MainProvider";
import ConsentBanner from "../component/ConsentBanner";

export default function PrivacyPolicy() {
  const { privacyRef } = useContext(mainFunctions);

  useEffect(() => {
    async function gotoTop() {
      await privacyRef.current.scrollIntoView();
    }

    gotoTop();
  }, []);

  return (
    <>
      <ConsentBanner />
      <Header />
      <section ref={privacyRef} className="hero2 kyc_bg">
        <div className="hero_title">Privacy Policy</div>
        <div className="hero_text mt-3">
          <strong>IziFin Data Protection Policy (NDPR Compliant)</strong>
        </div>
      </section>
      <section className="section_light">
        <ol>
          <li>
            <strong>
              <span data-contrast="auto" className="hero_text">
                Introduction
              </span>
            </strong>
          </li>
          <p>
            <span data-contrast="auto" className="hero_text">
              Izifin Technologies Limited is committed to protecting the privacy
              of its users and complying with the Nigeria Data Protection
              Regulation (NDPR). This Data Protection Policy outlines how we
              collect, store, use, and disclose Data Subject&rsquo;s personal
              data.
            </span>
          </p>
        </ol>

        <ol start="2">
          <li>
            <strong>
              <span data-contrast="auto">Purpose</span>
            </strong>
          </li>
          <p className="hero_text">
            <span data-contrast="auto">
              The paramount purpose is of this policy is to safeguard and secure
              every piece of data that Izifin Technologies Limited handles,
              stores, processes and/or channels, ensuring that it complies with
              data protection standards and regulations, namely the Nigerian
              Data Protection Regulation. This document also serves as
              compelling evidence of Izifin&rsquo;s dedication to data
              protection principles.
            </span>
          </p>
        </ol>

        <ol start="3">
          <li>
            <strong>
              <span data-contrast="auto">Definitions</span>
            </strong>
          </li>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="7"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"hybridMultilevel"}'
              data-aria-posinset="1"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Data Controller:</span>
              </strong>
              <span data-contrast="auto" className="hero_text">
                The entity (websites, applications etc) that determines the
                purposes and means of the processing of personal data.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="7"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"hybridMultilevel"}'
              data-aria-posinset="2"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Data Processor:</span>
              </strong>
              <span data-contrast="auto">
                The entity that engages in personal data processing on behalf of
                the controller. Please note that Processing involves any
                operation (or set) performed on personal data (such as, but not
                limited to, collection, structuring, storage, use or
                disclosure).
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="7"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"hybridMultilevel"}'
              data-aria-posinset="3"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Personal Data:</span>
              </strong>
              <span data-contrast="auto">
                This is any and all personally identifiable information (PII),
                and personal data as defined by the Applicable Data Protection
                Law or other data/information belonging to any individual that
                the Processor obtains in performing the Services under the
                Principal Agreement.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="7"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"hybridMultilevel"}'
              data-aria-posinset="4"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Data Subject:</span>
              </strong>
              <span data-contrast="auto">
                An individual who is the subject of Personal Data.
              </span>
            </li>
          </ul>
        </ol>

        <ol start="4">
          <li>
            <strong>
              <span data-contrast="auto">Scope</span>
            </strong>
          </li>
          <p>
            <span data-contrast="auto">
              The scope of this policy extends to all data, both at rest and in
              transit, managed by IziFin, encompassing data stored on-premises,
              in the cloud, within applications, and through third-party
              integrations.
            </span>
          </p>
        </ol>

        <ol start="5">
          <li>
            <strong>
              <span data-contrast="auto">Data We Collect</span>
            </strong>
          </li>
          <p>
            <span data-contrast="auto">
              We collect various data from users depending on their interaction
              with the platform:
            </span>
          </p>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="1"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="1"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Registration Information:</span>
              </strong>
              <span data-contrast="auto">
                {" "}
                Name, email address, phone number, and basic demographics.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="1"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="2"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Financial Information:</span>
              </strong>
              <span data-contrast="auto">
                {" "}
                Bank account details (with user consent for specific purposes),
                transaction history (with user consent for specific purposes).
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="1"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="3"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Usage Data:</span>
              </strong>
              <span data-contrast="auto">
                {" "}
                Information about how users interact with the platform, such as
                browsing history and features used.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="1"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="4"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Device Data:</span>
              </strong>
              <span data-contrast="auto">
                {" "}
                Device type, operating system, IP address (anonymized) for
                analytics purposes.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="1"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="5"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Survey Data:</span>
              </strong>
              <span data-contrast="auto">
                {" "}
                Information provided by users who participate in surveys offered
                by organizations on the platform (with user consent for specific
                purposes).
              </span>
            </li>
          </ul>
        </ol>

        <ol start="6">
          <li>
            <strong>
              <span data-contrast="auto">Legal Basis for Data Processing</span>
            </strong>
          </li>
          <p>
            <span data-contrast="auto">
              Izifin process data only with a lawful basis, including:
            </span>
          </p>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="2"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="1"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Consent:</span>
              </strong>
              <span data-contrast="auto">
                {" "}
                We obtain Data Subject&rsquo;s consent before collecting and
                using certain types of data, such as financial information
                through Explicit Consent.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="2"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="2"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Contract:</span>
              </strong>
              <span data-contrast="auto">
                We process Data Subject data to fulfill our contractual
                obligations to them, such as providing access to the platform
                and financial services.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="2"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="3"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Legal Obligation:</span>
              </strong>
              <span data-contrast="auto">
                {" "}
                We may need to process Data Subject data to comply with legal or
                regulatory requirements.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="2"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="4"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Legitimate Interests:</span>
              </strong>
              <span data-contrast="auto">
                {" "}
                We process Data Subject data for our legitimate interests, such
                as improving the platform's functionality and user experience,
                provided these interests do not override the Data
                Subject&rsquo;s fundamental rights and freedoms.
              </span>
            </li>
          </ul>
        </ol>

        <ol start="7">
          <li>
            <strong>
              <span data-contrast="auto">Data Protection Principles</span>
            </strong>
          </li>
          <p>
            <span data-contrast="auto">
              Izifin has implemented robust data protection measures in full
              alignment with the principles and requirements of the Nigeria Data
              Protection Regulation:
            </span>
          </p>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="8"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"hybridMultilevel"}'
              data-aria-posinset="1"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Accountability:</span>
              </strong>
              <span data-contrast="auto">
                Izifin&rsquo;s Data Controllers will continuously demonstrate
                compliance with the NDPR principles, including having
                appropriate policies and procedures in place, and adhering to
                best practices.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="8"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"hybridMultilevel"}'
              data-aria-posinset="2"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Integrity and Confidentiality:</span>
              </strong>
              <span data-contrast="auto">
                Personal data must be processed in a manner that ensures
                appropriate security, including protection against unauthorized
                or unlawful processing, accidental loss, destruction, or damage.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="8"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"hybridMultilevel"}'
              data-aria-posinset="3"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">
                  Lawfulness, Fairness, and Transparency:
                </span>
              </strong>
              <span data-contrast="auto">
                {" "}
                Personal data must be processed in a lawful, fair, and
                transparent manner, with clear communication to data subjects
                about how their data is being used.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="8"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"hybridMultilevel"}'
              data-aria-posinset="4"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Purpose Limitation:</span>
              </strong>
              <span data-contrast="auto">
                Personal data should only be collected for specific, explicit,
                and legitimate purposes, and not further processed in a manner
                incompatible with those purposes.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="8"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"hybridMultilevel"}'
              data-aria-posinset="5"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Data Minimization:</span>
              </strong>
              <span data-contrast="auto">
                The collection of personal data should be limited to what is
                necessary for the intended purpose, and no more.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="8"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"hybridMultilevel"}'
              data-aria-posinset="6"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Accuracy:</span>
              </strong>
              <span data-contrast="auto">
                Personal data must be accurate and, where necessary, kept up to
                date.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="8"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"hybridMultilevel"}'
              data-aria-posinset="7"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Storage limitation:</span>
              </strong>
              <span data-contrast="auto">
                Personal data should not be stored for longer than necessary for
                the intended purpose.
              </span>
            </li>
          </ul>
        </ol>

        <ol start="8">
          <li>
            <strong>
              <span data-contrast="auto">
                How We Use Data Subject&rsquo;s Data
              </span>
            </strong>
          </li>
          <p>
            <span data-contrast="auto">
              We use Data Subject&rsquo;s data for the following purposes:
            </span>
          </p>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="3"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="1"
              data-aria-level="1"
            >
              <span data-contrast="auto">
                To create and manage user account.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="3"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="2"
              data-aria-level="1"
            >
              <span data-contrast="auto">
                To provide Data Subjects with the services offered on the
                platform, such as financial services and access to surveys.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="3"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="3"
              data-aria-level="1"
            >
              <span data-contrast="auto">
                To improve the platform's functionality and user experience.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="3"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="4"
              data-aria-level="1"
            >
              <span data-contrast="auto">
                To send Data Subjects marketing communications (with Data
                Subject&rsquo;s consent).
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="3"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="5"
              data-aria-level="1"
            >
              <span data-contrast="auto">
                To comply with legal and regulatory requirements.&nbsp;
              </span>
            </li>
          </ul>
        </ol>

        <ol start="9">
          <li>
            <strong>
              <span data-contrast="auto">Data Sharing</span>
            </strong>
          </li>
          <p>
            <span data-contrast="auto">
              We will only share Data Subjects&rsquo; data with third parties
              with their consent, except in limited circumstances, such as:
            </span>
          </p>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="4"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="1"
              data-aria-level="1"
            >
              <span data-contrast="auto">
                To financial institutions for providing financial services
                (e.g., credit score calculations, loan applications) upon Data
                Subjects explicit consent.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="4"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="2"
              data-aria-level="1"
            >
              <span data-contrast="auto">
                To organizations conducting surveys on the platform, but only
                after anonymizing Data Subject&rsquo;s data.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="4"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="3"
              data-aria-level="1"
            >
              <span data-contrast="auto">
                To law enforcement or regulatory authorities as required by law.
              </span>
            </li>
          </ul>
          <p>
            <span data-contrast="auto">
              We will ensure any third-party with access to Data Subject&rsquo;s
              data implements appropriate security measures to protect it.
            </span>
          </p>
        </ol>

        <ol start="10">
          <li>
            <strong>
              <span data-contrast="auto">Data Security</span>
            </strong>
          </li>
          <p>
            <span data-contrast="auto">
              IziFin implements robust security measures to protect Data
              Subject&rsquo;s data from unauthorized access, disclosure,
              alteration, or destruction. These measures include:  &nbsp;
            </span>
          </p>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="5"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="1"
              data-aria-level="1"
            >
              <span data-contrast="auto">
                Secure data storage with encryption.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="5"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="2"
              data-aria-level="1"
            >
              <span data-contrast="auto">
                Regular security audits and penetration testing.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="5"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="3"
              data-aria-level="1"
            >
              <span data-contrast="auto">
                Access controls and user authentication mechanisms.
              </span>
            </li>
          </ul>
        </ol>

        <ol start="11">
          <li>
            <strong>
              <span data-contrast="auto">Data Subjects Data Rights</span>
            </strong>
          </li>
          <p>
            <span data-contrast="auto">
              Under the NDPR, Data Subjects have various rights regarding their
              personal data:
            </span>
          </p>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="6"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="1"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Right to Access:</span>
              </strong>
              <span data-contrast="auto">
                {" "}
                Data Subjects have the right to access their personal data held
                by IziFin.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="6"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="2"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Right to Rectification:</span>
              </strong>
              <span data-contrast="auto">
                {" "}
                Data Subjects can request IziFin to rectify any inaccuracies in
                their personal data.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="6"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="3"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Right to Erasure:</span>
              </strong>
              <span data-contrast="auto">
                {" "}
                Data Subjects have the right to request that IziFin erase their
                personal data.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="6"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="4"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Right to Restrict Processing:</span>
              </strong>
              <span data-contrast="auto">
                {" "}
                Data Subjects can restrict IziFin from processing their personal
                data for certain purposes.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="6"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="5"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Right to Object:</span>
              </strong>
              <span data-contrast="auto">
                {" "}
                Data Subjects have the right to object to IziFin processing
                their personal data for marketing purposes.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="6"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="6"
              data-aria-level="1"
            >
              <strong>
                <span data-contrast="auto">Right to Data Portability:</span>
              </strong>
              <span data-contrast="auto">
                {" "}
                Data Subjects can request to receive their personal data from
                IziFin in a structured, commonly used, and machine-readable
                format.
              </span>
            </li>
          </ul>
        </ol>

        <ol start="12">
          <li>
            <strong>
              <span data-contrast="auto">Data Breach Procedures&nbsp;</span>
            </strong>
          </li>
          <p>
            <span data-contrast="auto">
              In compliance with NITDA regulations, Izifin will report any data
              breach occurring on its platforms within a 72-hour timeframe. This
              timeline will be documented in Izifin&rsquo;s Data Protection
              Policy and Data Privacy Policy. A notification of data breach to
              NITDA will include the following information:
            </span>
          </p>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="6"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="7"
              data-aria-level="1"
            >
              <span data-contrast="auto">
                a) description of the circumstances of the loss or unauthorized
                access or disclosure;
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="6"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="8"
              data-aria-level="1"
            >
              <span data-contrast="auto">
                b) the date or time period during which the loss or unauthorized
                access or disclosure occurred;
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="6"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="9"
              data-aria-level="1"
            >
              <span data-contrast="auto">
                c) a description of the personal information involved in the
                loss or unauthorised access or disclosure;
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="6"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="10"
              data-aria-level="1"
            >
              <span data-contrast="auto">
                d) an assessment of the risk of harm to individuals as a result
                of the loss or unauthorised access or disclosure;
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="6"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="11"
              data-aria-level="1"
            >
              <span data-contrast="auto">
                e) an estimate of the number of individuals to whom there is a
                real risk of significant harm as a result of the loss or
                unauthorized access or disclosure;
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="6"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="12"
              data-aria-level="1"
            >
              <span data-contrast="auto">
                f) a description of steps the organization has taken to reduce
                the risk of harm to individuals;
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="6"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="13"
              data-aria-level="1"
            >
              <span data-contrast="auto">
                g) a description of any steps the organisation has taken to
                notify individuals of the loss or unauthorized access or
                disclosure, and
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="6"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="14"
              data-aria-level="1"
            >
              <span data-contrast="auto">
                h) the name and contact information for a person who can answer,
                on behalf of the organization, the Agency&rsquo;s questions
                about the loss of unauthorized access or disclosure.
              </span>
            </li>
          </ul>
          <ul>
            <li
              data-leveltext=""
              data-font="Symbol"
              data-listid="6"
              data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
              data-aria-posinset="15"
              data-aria-level="1"
            >
              <span data-contrast="auto">
                Additionally, Izifin will immediately notify the Data Subject of
                the Personal Data breach where the personal data breach will
                likely result in high risks to the freedoms and rights of the
                Data Subject.
              </span>
            </li>
          </ul>
        </ol>

        <ol start="13">
          <li>
            <strong>
              <span data-contrast="auto">Roles and Responsibilities:</span>
            </strong>
          </li>
          <ol start="1">
            <li>
              <strong>
                <span data-contrast="auto">
                  Data Protection Officer (DPO)- Josiah Okoye (
                </span>
              </strong>
              <a href="mailto:josiah@izifin.com">
                <span data-contrast="none">
                  <span data-ccp-char="">josiah@izifin.com</span>
                </span>
              </a>
              <strong>
                <span data-contrast="auto">)&nbsp;</span>
              </strong>
            </li>
            <ul>
              <li
                data-leveltext=""
                data-font="Symbol"
                data-listid="9"
                data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
                data-aria-posinset="1"
                data-aria-level="1"
              >
                <span data-contrast="auto">
                  Oversees compliance with the NDPR and internal data protection
                  policies.
                </span>
              </li>
            </ul>
            <ul>
              <li
                data-leveltext=""
                data-font="Symbol"
                data-listid="9"
                data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
                data-aria-posinset="2"
                data-aria-level="1"
              >
                <span data-contrast="auto">
                  Manages data subject requests and inquiries.
                </span>
              </li>
            </ul>
            <ul>
              <li
                data-leveltext=""
                data-font="Symbol"
                data-listid="9"
                data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
                data-aria-posinset="3"
                data-aria-level="1"
              >
                <span data-contrast="auto">
                  Conducts data protection impact assessments and risk
                  management.
                </span>
              </li>
            </ul>
            <ul>
              <li
                data-leveltext=""
                data-font="Symbol"
                data-listid="9"
                data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
                data-aria-posinset="4"
                data-aria-level="1"
              >
                <span data-contrast="auto">
                  Collaborates with other departments on data privacy
                  initiatives.
                </span>
              </li>
            </ul>
          </ol>

          <ol start="2">
            <li>
              <strong>
                <span data-contrast="auto">
                  Senior Backend Developer Emmanuel Adebiyi (
                </span>
              </strong>
              <a href="mailto:emmanuel@izifin.com">
                <span data-contrast="none">
                  <span data-ccp-char="">emmanuel@izifin.com</span>
                </span>
              </a>
              <strong>
                <span data-contrast="auto">)</span>
              </strong>
            </li>
            <ul>
              <li
                data-leveltext=""
                data-font="Symbol"
                data-listid="10"
                data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
                data-aria-posinset="1"
                data-aria-level="1"
              >
                <span data-contrast="auto">
                  Oversees the development of secure and efficient applications,
                  ensuring the protection of customer data across all systems.
                </span>
              </li>
            </ul>
            <ul>
              <li
                data-leveltext=""
                data-font="Symbol"
                data-listid="10"
                data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
                data-aria-posinset="2"
                data-aria-level="1"
              >
                <span data-contrast="auto">
                  Implements secure coding practices to protect user data.
                </span>
              </li>
            </ul>
            <ul>
              <li
                data-leveltext=""
                data-font="Symbol"
                data-listid="10"
                data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
                data-aria-posinset="3"
                data-aria-level="1"
              >
                <span data-contrast="auto">
                  Participates in security assessments and vulnerability
                  testing.
                </span>
              </li>
            </ul>
            <ul>
              <li
                data-leveltext=""
                data-font="Symbol"
                data-listid="10"
                data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
                data-aria-posinset="4"
                data-aria-level="1"
              >
                <span data-contrast="auto">
                  Maintains secure data storage and access controls.
                </span>
              </li>
            </ul>
            <ul>
              <li
                data-leveltext=""
                data-font="Symbol"
                data-listid="10"
                data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"multilevel"}'
                data-aria-posinset="5"
                data-aria-level="1"
              >
                <span data-contrast="auto">
                  Collaborates with the DPO on data privacy initiatives.
                </span>
              </li>
            </ul>
            <p>
              <span data-contrast="auto">
                Combined, these roles ensure IziFin prioritizes and upholds the
                highest standards of user data protection.
              </span>
            </p>
          </ol>
        </ol>

        <ol start="14">
          <li>
            <strong>
              <span data-contrast="auto">Contacts</span>
            </strong>
          </li>
          <p>
            <span data-contrast="auto">
              For any questions or concerns regarding this Data Protection
              Policy or Data Subjects&rsquo; data rights, a channel for
              contacting Izifin&rsquo;s Data Protection Officer (DPO) will be
              provided on the agreed advertised sites through{" "}
            </span>
            <a href="mailto:josiah@izifin.com">
              <span data-contrast="none">
                <span data-ccp-char="">josiah@izifin.com</span>
              </span>
            </a>
            <span data-contrast="auto">.&nbsp;</span>
          </p>
        </ol>

        <ol start="15">
          <li>
            <strong>
              <span data-contrast="auto">15. Changes to This Policy</span>
            </strong>
          </li>
          <p>
            <span data-contrast="auto">
              We may update this Data Protection Policy periodically. We will
              notify users of any significant changes.
            </span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                This policy ensures Izifin Technologies Limited adheres to the
                NDPR and empowers users with control over their data.
              </span>
            </strong>
          </p>
        </ol>
      </section>
      <Footer />
    </>
  );
}
