import React, { useContext, useEffect } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { mainFunctions } from "../providers/MainProvider";
import ConsentBanner from "../component/ConsentBanner";

export default function Izibnpl() {
  const { bnplRef } = useContext(mainFunctions);

  useEffect(() => {
    async function gotoTop() {
      await bnplRef.current.scrollIntoView();
    }

    gotoTop();
  }, []);
  return (
    <>
      <ConsentBanner />
      <Header />
      <section className="hero2 bnpl_bg" ref={bnplRef}>
        <div className="hero_title">IZI BNPL</div>
        <div className="hero_text">
          One integration, few minutes and numerous customers. BNPL couldn't be
          more simple and effective. Offer more ways to pay for increased
          checkout conversion and higher average order values. Powers banking,
          retail and payment businesses. Increase your revenue.
        </div>
      </section>
      <section className="section_light">
        <div className="section_list2 row mb-5">
          <div className="col-xs-12 col-sm-12 col-md-4 list_box">
            <div className="list_item">
              <div className="image bnpl_finance"></div>
              <div className="section_list_title">BNPL (FINANCE)</div>
              <div className="section_list_sub">
                Optimize sales and cash flow with BNPL financed. Build loyalty
                and earn your customers' trust while saving more on decisioning
                technology by implementing the BNPL Financed.
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-4 list_box">
            <div className="list_item">
              <div className="image bnpl_decision"></div>
              <div className="section_list_title">BNPL (Decision Only)</div>
              <div className="section_list_sub">
                Get onboarded for free and only pay per decisioning. With the
                BNPL decisioning, you are able to assess customers credit
                status, make 'buy now, pay later' offers to them and increase
                your revenue.
              </div>
            </div>
          </div>
        </div>
        <center>
          <a href="https://panel.izifin.com/auth/getstarted">
            <div className="btn btn_solid get_started_btn">Get started</div>
          </a>
        </center>
      </section>
      <Footer />
    </>
  );
}
