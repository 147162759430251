import React, { useContext } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import About from "../component/About";
import Products from "../component/Products";
import Pricing from "../component/Pricing";
import Hero from "../component/Hero";
import Footnotes from "../component/Footnotes";
import { mainFunctions } from "../providers/MainProvider";
import ConsentBanner from "../component/ConsentBanner";

export default function Home() {
  const { productsRef, aboutRef, pricingRef, innerRef } =
    useContext(mainFunctions);

  return (
    <div ref={innerRef}>
      <ConsentBanner />
      <Header
        innerRef={innerRef}
        aboutRef={aboutRef}
        productsRef={productsRef}
        pricingRef={pricingRef}
        showHomeNav={true}
      />
      <Hero />
      <div ref={aboutRef}>
        <About />
      </div>
      <div ref={productsRef}>
        <Products />
      </div>
      <div ref={pricingRef}>
        <Pricing />
      </div>
      <Footer />
      <Footnotes />
    </div>
  );
}
